import { SchemaJourneyResponseDto } from '@jam/api-sdk';
import { useGetJourneysDetailsQuery } from '../../redux/api/cmsApi';
import {
	JourneyContentAllocationDetails,
	useGetUserJourneyContentAllocationQuery,
} from '../../redux/api/learnerApi';

const getFirstNotCompletedStory = (
	userJourneys: JourneyContentAllocationDetails[],
	journeyDetails: SchemaJourneyResponseDto[]
) => {
	const completedStories = userJourneys.flatMap(
		(journey) => journey.completedStoryIds
	);

	for (const userJourney of userJourneys) {
		for (const [index, journey] of journeyDetails.entries()) {
			for (const story of journey.stories) {
				if (!completedStories.includes(story.id)) {
					return {
						contentAllocationId: userJourney.id,
						story,
						journeyId: journey.id,
						journey,
						journeyIndex: index,
					};
				}
			}
		}
	}
	return null;
};

export const useGetFirstNotCompletedStory = () => {
	const { data: userJourneys } = useGetUserJourneyContentAllocationQuery();
	const { data: journeys } = useGetJourneysDetailsQuery();

	if (!journeys || !userJourneys) {
		return undefined;
	}
	return getFirstNotCompletedStory(userJourneys, journeys);
};
