import {
	Box,
	Divider,
	Flex,
	SimpleGrid,
	Skeleton,
	Stack,
	Text,
} from '@chakra-ui/react';
import { SchemaKeyStoryForImprovementDto } from '@jam/api-sdk';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Chip } from '../../../../../common/components/Chip';
import { useGetDetailedUserKeyMissionsForImprovementQuery } from '../../../../../redux/api/dashboardApi';

interface Props {
	story: SchemaKeyStoryForImprovementDto;
}

const KeyImprovementStoryCard: React.FC<Props> = ({ story }: Props) => {
	const { t } = useTranslation('dashboard');
	return (
		<Box border={'1px'} borderColor={'#D9D9D9'} borderRadius={'24px'} p="6">
			<Text fontSize={'16px'} fontWeight={'500'}>
				{story.storyTitle}
			</Text>
			<Flex mt="6" direction={'column'} gap={2}>
				<Text fontSize={'12px'} color="#757575">
					{t('dashboard:detailed_user_view:from_track')}
				</Text>
				<Chip
					px={3}
					py={1}
					borderRadius={'8px'}
					bgColor={'#FAFAFA'}
					borderColor={'#D9D9D9'}
					fontWeight={'500'}
					color="#757575"
					text={
						story.journeyTitle ??
						t('dashboard:detailed_user_view:no_track_name')
					}
				/>
			</Flex>
			<Divider mt="6" />
			<Stack
				mt="6"
				alignItems={'center'}
				direction={'row'}
				spacing={4}
				divider={<Divider orientation="vertical" height={'30px'} />}
			>
				<Flex direction={'column'} gap={2}>
					<Text fontSize={'12px'} color="#757575">
						{t('dashboard:detailed_user_view:average_score')}
					</Text>
					<Chip
						px={3}
						py={1}
						bgColor={'#F5F5F5'}
						border={0}
						color="#757575"
						text={story.averageScore.toString()}
					/>
				</Flex>
				<Flex direction={'column'} gap={2}>
					<Text fontSize={'12px'} color="#757575">
						{t('dashboard:detailed_user_view:scores_in_the_last_3_rounds')}
					</Text>
					<Flex gap="2">
						{story.scorePerRound.map((score, index) => (
							<Chip
								key={`${index}-${score}`}
								px={3}
								py={1}
								border={0}
								bgColor={'#FDEEEE'}
								color="#B91C1C"
								text={score.toString()}
							/>
						))}
					</Flex>
				</Flex>
			</Stack>
		</Box>
	);
};

const LoadingState = () => {
	return (
		<Flex direction={'column'} gap="4">
			<Box maxW="300px">
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
			</Box>
			<SimpleGrid mt="6" columns={1} gap={10} h={'100%'}>
				<Skeleton borderRadius={'8px'} h="275px" flex={1} />
			</SimpleGrid>
		</Flex>
	);
};

export const KeyImprovementStories = () => {
	const { t } = useTranslation('dashboard');
	const { userId } = useParams<{ userId?: string }>();
	const { data, isLoading, error } =
		useGetDetailedUserKeyMissionsForImprovementQuery(userId ?? '', {
			skip: !userId,
		});

	if (!userId) {
		return <p>No user ID provided</p>;
	}

	if (isLoading) {
		return <LoadingState />;
	}
	if (error) {
		return <p>Failed to load key missions. Please try again later.</p>;
	}

	return (
		<Box>
			{data && data.length > 0 ? (
				<>
					<Text fontSize={'24px'} fontWeight={'500'} color={'#0B1215'}>
						{t('dashboard:detailed_user_view:key_missions_for_improvement')}
					</Text>
					<SimpleGrid mt="6" columns={3} gap={10}>
						{data?.map(
							(storyForImprovement: SchemaKeyStoryForImprovementDto) => (
								<KeyImprovementStoryCard
									key={`${storyForImprovement.storyReferenceId}-${storyForImprovement.averageScore}`}
									story={storyForImprovement}
								/>
							)
						)}
					</SimpleGrid>
				</>
			) : (
				<Text fontSize={'24px'} fontWeight={'500'} color={'#0B1215'}>
					{t(
						'dashboard:detailed_user_view:no_key_missions_for_improvement_available'
					)}
				</Text>
			)}
		</Box>
	);
};
