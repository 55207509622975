import {
	SchemaCompletedUserActivityReposeDto,
	SchemaCompletionsWithTrendsResponseDto,
	SchemaDetailedUserOverviewResponseDto,
	SchemaJourneyContentAllocationStatusResponseDto,
	SchemaKeyStoryForImprovementDto,
	SchemaSessionResponseDto,
	SchemaSessionsTeamActivityReposeDto,
	SchemaTeamResponseDto,
	SchemaUserActivityReposeDto,
	SchemaWeekDataNumberResponseDto,
	SchemaWeeklyActivityAcrossAllUsersResponseDto,
} from '@jam/api-sdk';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

const createTeamLink = (link: string, teamId: string | undefined) =>
	teamId ? `${link}?teamId=${teamId}` : link;

const api = createApi({
	reducerPath: 'dashboard-api',
	baseQuery: fetchBaseQuery({
		baseUrl: `${
			process.env
				.REACT_APP_SALES_ENABLEMENT_PLATFORM_NEST_API_BASE_URL as string
		}/api/v1`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).applicationState.accessToken;

			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}

			return headers;
		},
	}),
	endpoints: (builder) => ({
		getSessionActivity: builder.query<
			SchemaCompletedUserActivityReposeDto,
			string | undefined
		>({
			query: (teamId: string | undefined) =>
				createTeamLink('user-activity/session-activity', teamId),
		}),
		getUsageInsights: builder.query<
			SchemaUserActivityReposeDto[],
			string | undefined
		>({
			query: (teamId: string | undefined) =>
				createTeamLink('user-activity/activity-per-user', teamId),
		}),
		getTeamCompletions: builder.query<
			SchemaCompletionsWithTrendsResponseDto,
			string | undefined
		>({
			query: (teamId: string | undefined) =>
				createTeamLink('team-activity/completions', teamId),
		}),
		getWeeklyActivity: builder.query<
			SchemaWeeklyActivityAcrossAllUsersResponseDto,
			string | undefined
		>({
			query: (teamId: string | undefined) =>
				createTeamLink('team-activity/weekly-activity', teamId),
		}),
		getRolePlayActivity: builder.query<
			SchemaSessionsTeamActivityReposeDto[],
			string | undefined
		>({
			query: (teamId: string | undefined) =>
				createTeamLink('team-activity/all-sessions', teamId),
		}),
		getDetailedUserKeyMissionsForImprovement: builder.query<
			SchemaKeyStoryForImprovementDto[],
			string
		>({
			query: (userId: string) =>
				`detailed-user-activity/key-stories?userId=${userId}`,
		}),
		getDetailedUserActivityOverview: builder.query<
			SchemaDetailedUserOverviewResponseDto,
			string
		>({
			query: (userId: string) =>
				`detailed-user-activity/overview?userId=${userId}`,
		}),
		getDetailedUserWeeklyActivity: builder.query<
			SchemaWeekDataNumberResponseDto,
			string
		>({
			query: (userId: string) =>
				`detailed-user-activity/weekly-activity?userId=${userId}`,
		}),
		getDetailedUserCompletions: builder.query<
			SchemaCompletionsWithTrendsResponseDto,
			string
		>({
			query: (userId: string) =>
				`detailed-user-activity/completions?userId=${userId}`,
		}),

		getUserSessions: builder.query<SchemaSessionResponseDto[], string>({
			query: (userId: string) =>
				`detailed-user-activity/sessions?userId=${userId}`,
		}),

		getUserJourneyState: builder.query<
			SchemaJourneyContentAllocationStatusResponseDto,
			{
				userId: string;
				journeyId: string;
			}
		>({
			query: ({ userId, journeyId }: { userId: string; journeyId: string }) =>
				`detailed-user-activity/journey-state?userId=${userId}&journeyId=${journeyId}`,
		}),

		getOrgTeams: builder.query<SchemaTeamResponseDto[], { orgId: string }>({
			query: ({ orgId }: { orgId: string }) => `team/organization/${orgId}`,
		}),
	}),
});

export const {
	useGetSessionActivityQuery,
	useGetUsageInsightsQuery,
	useGetTeamCompletionsQuery,
	useGetWeeklyActivityQuery,
	useGetRolePlayActivityQuery,
	useGetDetailedUserActivityOverviewQuery,
	useGetDetailedUserKeyMissionsForImprovementQuery,
	useGetDetailedUserWeeklyActivityQuery,
	useGetDetailedUserCompletionsQuery,
	useGetUserSessionsQuery,
	useGetUserJourneyStateQuery,
	useGetOrgTeamsQuery,
} = api;
export default api;
