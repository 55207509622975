import {
	Flex,
	Tab,
	TabIndicator,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
} from '@chakra-ui/react';
import { Lock } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetJourney } from '../hooks/useGetCurrentJourney';
import { JourneyMissionList } from './JourneyMissionList';

export const JourneyDetail = () => {
	const { journeyId } = useParams();
	const journey = useGetJourney(journeyId);
	const { t } = useTranslation('common');
	if (!journey) {
		return null;
	}

	return (
		<Flex direction="column">
			<Text fontWeight={'bold'} fontSize={'40px'}>
				{journey.title}
			</Text>
			<Text
				color={'#757575'}
				fontSize={'16px'}
				lineHeight={'24px'}
				maxW={'800px'}
			>
				{journey.description}
			</Text>
			<Tabs mt="5" isLazy>
				<TabList>
					<Tab
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						{t('missions')}
					</Tab>
					<Tab
						isDisabled
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						<Flex alignItems={'center'} gap={1}>
							<Text>{t('stats')}</Text>
							<Lock size={14} />
						</Flex>
					</Tab>
				</TabList>
				<TabIndicator
					mt="-1.5px"
					height="2px"
					bg="#4241E4"
					borderRadius="1px"
				/>
				<TabPanels>
					<TabPanel px="0">
						<JourneyMissionList
							journeyId={journey.id}
							missions={journey.stories}
						/>
					</TabPanel>
					<TabPanel px="0">
						<Text>Stats</Text>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Flex>
	);
};
