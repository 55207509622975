import { Box, Flex, Progress, SimpleGrid, Text } from '@chakra-ui/react';
import { Package } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { Chip } from '../../../common/components/Chip';
import { JourneyLabels } from '../../../common/components/JourneyLabels';
import { calculateJourneyCompletion } from '../../../common/utils/calculateCompletion';
import { useGetFirstNotCompletedStory } from '../../../common/utils/getfirstNotCompletedStory';
import { useGetJourneysDetailsQuery } from '../../../redux/api/cmsApi';
import { useGetUserJourneyContentAllocationQuery } from '../../../redux/api/learnerApi';

export const AllJourneys = () => {
	const { t } = useTranslation('gym');
	const { data: userJourneys } = useGetUserJourneyContentAllocationQuery();
	const activeStory = useGetFirstNotCompletedStory();
	const { data: journeys } = useGetJourneysDetailsQuery();

	if (!journeys || !userJourneys) {
		return null;
	}

	const activeJourney: string = activeStory?.journey.id || '';
	const otherJourneys = journeys.filter(
		(journey) => journey.id !== activeJourney
	);

	if (!otherJourneys.length) {
		return null;
	}

	return (
		<Box my="10">
			<Flex mb="6" alignItems={'center'} gap="2">
				<Text
					w="fit-content"
					fontSize="2xl"
					color={'#757575'}
					fontWeight="semibold"
				>
					{t('all_tracks')}
				</Text>
			</Flex>
			<Flex direction="column" gap="4">
				{otherJourneys.map((journey) => {
					const completion: {
						completed: number;
						total: number;
						percentage: number;
					} = calculateJourneyCompletion(journey, userJourneys);
					return (
						<Box
							key={journey.id}
							p="4"
							border={'1px'}
							borderColor={'#d9d9d9'}
							borderRadius={'32px'}
						>
							<Flex justify={'space-between'}>
								<Box>
									<Text fontSize="24px" fontWeight="medium">
										{journey.title}
									</Text>
									<JourneyLabels journey={journey} />
								</Box>
								{/* <Box>
									<Button
										borderRadius={'32px'}
										textColor={'white'}
										bg={'#4241E4'}
										gap={'16px'}
										_hover={{ bg: '#4241E4' }}
										onClick={() => {}}
									>
										<Text>{t('resume')}</Text>
										<Image src="/icons/right-arrow.svg" />
									</Button>
								</Box> */}
							</Flex>
							<Flex mt="4" gap={'2'} alignItems={'center'}>
								<Chip
									display={'inline-block'}
									bg={'#D5C9FF'}
									text={`${completion.completed}/${completion.total}`}
								/>
								<Box w="100%">
									<Progress
										borderRadius={'25px'}
										size={'lg'}
										value={completion.completed}
										max={completion.total}
									/>
								</Box>
							</Flex>
							<SimpleGrid mt="4" columns={4}>
								<Flex gap="2">
									<Flex
										p="2"
										border="1px"
										borderRadius={'12px'}
										borderColor={'#d9d9d9'}
										justifyContent={'center'}
										alignItems={'center'}
									>
										<Package size={32} />
									</Flex>
									<Box>
										<Text fontSize={'12px'} color="#757575">
											{t('content')}
										</Text>
										<Text fontSize={'14px'} fontWeight={'medium'}>
											{journey.stories.length} {t('missions')}
										</Text>
									</Box>
								</Flex>
							</SimpleGrid>
						</Box>
					);
				})}
			</Flex>
		</Box>
	);
};
