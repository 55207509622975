import { useAuthInfo } from '@propelauth/react';
import { skipToken } from '@reduxjs/toolkit/query';
import {
	useGetAllSessionsByUserIdQuery,
	useGetUserJourneyContentAllocationQuery,
} from '../../redux/api/learnerApi';

export enum StoryStatus {
	NOT_STARTED = 'NOT_STARTED',
	IN_PROGRESS = 'IN_PROGRESS',
	COMPLETED = 'COMPLETED',
}

export const useStoryStatus = (storyId: string): StoryStatus => {
	const { user } = useAuthInfo();
	const { data: userJourneys } = useGetUserJourneyContentAllocationQuery();
	const { data: calls } = useGetAllSessionsByUserIdQuery(
		user?.userId ?? skipToken,
		{
			refetchOnMountOrArgChange: true,
		}
	);

	if (!userJourneys) {
		return StoryStatus.NOT_STARTED;
	}

	const completedStories = userJourneys.flatMap(
		(userJourney) => userJourney.completedStoryIds
	);

	// Check if the story is completed by checking if the storyId is in the completedStories array
	if (completedStories.includes(storyId)) {
		return StoryStatus.COMPLETED;
	}

	// Check if the story is in progress by checking if the storyId is in the calls array
	if (calls && calls.some((call) => call.storyReferenceId === storyId)) {
		return StoryStatus.IN_PROGRESS;
	}

	// If the story is not completed or in progress, it is not started
	return StoryStatus.NOT_STARTED;
};
