import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { SchemaStoryResponseDto } from '@jam/api-sdk';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StoryLabels } from '../../../common/components/StoryLabels';
import {
	StoryStatus,
	useStoryStatus,
} from '../../../common/hooks/useStoryStatus';

type StoryDetailsProps = {
	number: number;
	isLast: boolean;
	story: SchemaStoryResponseDto;
	contentAllocationId: string;
	journeyName: string;
};

type ButtonProps = {
	text: string;
	bg: string;
	textColor: string;
};

const ButtonByStoryStatus: Record<StoryStatus, ButtonProps> = {
	NOT_STARTED: {
		text: 'start',
		bg: 'white',
		textColor: 'black',
	},
	IN_PROGRESS: {
		text: 'resume',
		bg: 'white',
		textColor: 'black',
	},
	COMPLETED: {
		text: 'refresh',
		bg: '#4241E4',
		textColor: 'white',
	},
};

export const StoryDetails = ({
	number,
	isLast,
	story,
	contentAllocationId,
	journeyName,
}: StoryDetailsProps) => {
	const { t } = useTranslation('gym');
	const status = useStoryStatus(story.id);
	const buttonProps = ButtonByStoryStatus[status];
	const navigate = useNavigate();

	return (
		<Flex position={'relative'} w="100%" gap="5">
			<Flex
				justifyContent={'center'}
				alignItems={'center'}
				w="55px"
				h="50px"
				my={'auto'}
				border={'1px'}
				borderColor={'#d9d9d9'}
				backgroundColor={status === 'COMPLETED' ? '#D5C9FF' : 'white'}
				borderRadius={'full'}
			>
				<Text fontSize={'14px'} color={'#757575'}>
					{number}
				</Text>
				{!isLast && (
					<Box
						position={'absolute'}
						bottom={'-50px'}
						w="5px"
						zIndex={-1}
						backgroundColor={'#D5C9FF'}
						h="60px"
					></Box>
				)}
			</Flex>
			<Flex
				w="100%"
				justifyContent={'space-between'}
				border={'1px'}
				borderColor={'#d9d9d9'}
				borderRadius={'24px'}
				px={4}
				py={4}
				gap="2"
			>
				<Flex gap="2">
					<Image
						borderRadius={'12px'}
						src={
							story.persona.avatar_file.sizes.thumbnail?.url ??
							story.persona.avatar_file.url ??
							''
						}
						w="50px"
						h="50px"
					/>
					<Box>
						<Text fontSize={'14px'} fontWeight={'medium'}>
							{story.title}
						</Text>
						<Text fontSize={'12px'} color={'#757575'}>
							{`${story.persona.role} ${story.persona.workplace_name && `@${story.persona.workplace_name}`}`}
						</Text>
					</Box>
					<Box pl="3">
						<StoryLabels story={story} />
					</Box>
				</Flex>
				<Box>
					<Button
						id="start-story-button"
						borderRadius={'full'}
						border={'1px'}
						px="5"
						borderColor={'black'}
						size={'xs'}
						textColor={buttonProps.textColor}
						bg={buttonProps.bg}
						_hover={{ bg: buttonProps.bg }}
						onClick={() => {
							navigate(
								`/call?storyId=${story.id}&contentAllocationId=${contentAllocationId ?? ''}&ref=gym&journey=${journeyName}`
							);
						}}
					>
						<Text>{t(buttonProps.text)}</Text>
					</Button>
				</Box>
			</Flex>
		</Flex>
	);
};
