import { Box, Flex, SkeletonText, Text } from '@chakra-ui/react';
import { Check, Circle } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useGetFirstStepsGoalsQuery } from '../../../redux/api/learnerApi';

const LoadingState = () => {
	return (
		<Box
			bg="#FCFCFC"
			border={'1px'}
			borderColor={'#D9D9D9'}
			borderRadius={'24px'}
			p="3"
			minW={'360px'}
			w="fit-content"
		>
			<SkeletonText noOfLines={5} spacing="5" />
		</Box>
	);
};

export const OnboardingGoals = () => {
	const { t } = useTranslation('home');
	const { data, isLoading } = useGetFirstStepsGoalsQuery();
	if (isLoading) {
		return <LoadingState />;
	}

	return (
		<Box
			bg="#FCFCFC"
			border={'1px'}
			borderColor={'#D9D9D9'}
			borderRadius={'24px'}
			p="3"
			minW={'360px'}
			w="fit-content"
		>
			<Text fontSize={'12px'} color="#757575" fontWeight={'medium'}>
				{t('onboarding_goals_title')}
			</Text>
			<Flex mt="4" gap="2" direction="column">
				<Flex justifyContent={'space-between'}>
					<Text
						textDecoration={data?.firstStoryCompleted ? 'line-through' : 'none'}
						fontSize={'12px'}
					>
						{t('onboarding_goals_mission')}
					</Text>
					<Flex
						alignItems={'center'}
						justifyContent={'center'}
						h={'fit-content'}
						w={'fit-content'}
						p={'2px'}
						borderRadius={'full'}
						bgColor={data?.firstStoryCompleted ? '#EBF934' : 'white'}
					>
						{data?.firstStoryCompleted ? (
							<Check size={15} />
						) : (
							<Circle size={15} />
						)}
					</Flex>
				</Flex>
				<Flex justifyContent={'space-between'}>
					<Text
						textDecoration={
							data?.firstJourneyCompleted ? 'line-through' : 'none'
						}
						fontSize={'12px'}
					>
						{t('onboarding_goals_track')}
					</Text>
					<Flex
						alignItems={'center'}
						justifyContent={'center'}
						h={'fit-content'}
						w={'fit-content'}
						p={'2px'}
						borderRadius={'full'}
						bgColor={data?.firstJourneyCompleted ? '#EBF934' : 'white'}
					>
						{data?.firstJourneyCompleted ? (
							<Check size={15} />
						) : (
							<Circle size={15} />
						)}
					</Flex>
				</Flex>
				<Flex justifyContent={'space-between'}>
					<Text
						textDecoration={
							data?.firstRefresherCompleted ? 'line-through' : 'none'
						}
						fontSize={'12px'}
					>
						{t('onboarding_goals_refresher')}
					</Text>
					<Flex
						alignItems={'center'}
						justifyContent={'center'}
						h={'fit-content'}
						w={'fit-content'}
						p={'2px'}
						borderRadius={'full'}
						bgColor={data?.firstRefresherCompleted ? '#EBF934' : 'white'}
					>
						{data?.firstRefresherCompleted ? (
							<Check size={15} />
						) : (
							<Circle size={15} />
						)}
					</Flex>
				</Flex>
			</Flex>
		</Box>
	);
};
