import { SchemaJourneyResponseDto } from '@jam/api-sdk';
import {
	JourneyContentAllocationDetails,
	useGetUserJourneyContentAllocationQuery,
} from '../../redux/api/learnerApi';

export const calculateJourneyCompletion = (
	journey: SchemaJourneyResponseDto,
	activeJourneysData: JourneyContentAllocationDetails[]
) => {
	// Get all the completed stories
	const completedStories = activeJourneysData.flatMap(
		(x) => x.completedStoryIds
	);

	// Filter out the completed stories that are in the journey
	const completedStoriesOnJourney = completedStories.filter((storyId) =>
		journey.stories.find((story) => story.id === storyId)
	);

	// Calculate the percentage of completion
	const percentage = Math.round(
		(completedStoriesOnJourney.length / journey.stories.length) * 100
	);

	// Return the completion data
	return {
		completed: completedStoriesOnJourney.length,
		total: journey.stories.length,
		percentage,
	};
};

export const useCalculateJourneyCompletion = (
	journey: SchemaJourneyResponseDto | undefined | null
) => {
	const { data: activeJourneyData } = useGetUserJourneyContentAllocationQuery();
	if (!journey || !activeJourneyData) {
		return { completed: 0, total: 0, percentage: 0 };
	}
	return calculateJourneyCompletion(journey, activeJourneyData);
};
