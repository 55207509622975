import { SchemaJourneyResponseDto } from '@jam/api-sdk';
import {
	useGetJourneyDetailsQuery,
	useGetJourneysDetailsQuery,
} from '../../../redux/api/cmsApi';

export const useGetJourney = (
	journeyId: string | undefined
): SchemaJourneyResponseDto | null | undefined => {
	const { data: journey } = useGetJourneyDetailsQuery(journeyId || '', {
		skip: !journeyId,
	});

	if (!journeyId) {
		return undefined;
	}

	if (!journey) {
		return null;
	}

	return journey;
};

export const useGetMission = (
	missionId: string | undefined
): SchemaJourneyResponseDto['stories'][0] | null | undefined => {
	const { data: journeys } = useGetJourneysDetailsQuery();

	if (!missionId) {
		return undefined;
	}

	if (!journeys) {
		return null;
	}

	const stories = journeys.flatMap((journey) => journey.stories);

	return stories.find((mission) => mission.id === missionId) || null;
};
