import { StringParam, useQueryParam, withDefault } from 'use-query-params';

export const useReferer = () => {
	const [ref, setRef] = useQueryParam<string | null>(
		'ref',
		withDefault(StringParam, null)
	);

	return { ref, setRef };
};
