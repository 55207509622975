import { Box, Flex, IconButton, Stack, Text } from '@chakra-ui/react';
import { CaretLeft } from '@phosphor-icons/react';
import { Link, useNavigate } from 'react-router-dom';
import { UserWidgets } from '../../../common/components/UserWidgets';

type BreadCrumbProps = {
	backLink: string;
	items: {
		title: string;
		link?: string;
	}[];
};

export const BreadCrumbs = ({ items, backLink }: BreadCrumbProps) => {
	const navigate = useNavigate();
	return (
		<Box w="100%" mb="5" borderRadius={'md'} role="heading">
			<Flex
				direction={'row'}
				gap={10}
				justifyContent={'space-between'}
				alignItems={{ base: 'column', lg: 'center' }}
				w="100%"
			>
				<Flex alignItems={'center'} gap={3}>
					<IconButton
						width={'fit-content'}
						w={'fit-content'}
						px={0}
						h={'24px'}
						bg="gray.100"
						color={'#0B1215'}
						_hover={{ bg: 'gray.200' }}
						aria-label="Back"
						icon={<CaretLeft size={14} />}
						onClick={() => {
							navigate(backLink);
						}}
					/>
					<Stack
						direction="row"
						gap={3}
						divider={
							<Text color="#757575" fontSize={'14px'}>
								/
							</Text>
						}
					>
						<Link to={'/journeys'}>
							<Text color="#757575" fontSize={'14px'}>
								Tracks
							</Text>
						</Link>
						{items.map((item, index) => (
							<Link to={item.link || '#'} key={index}>
								<Text
									color={items.length === index + 1 ? 'black' : '#757575'}
									fontSize={'14px'}
								>
									{item.title}
								</Text>
							</Link>
						))}
					</Stack>
				</Flex>
				<UserWidgets />
			</Flex>
		</Box>
	);
};
