import { Select } from '@chakra-ui/react';
import { useUserOrg } from '../../../../../common/hooks/useUserOrg';
import { useGetOrgTeamsQuery } from '../../../../../redux/api/dashboardApi';
import { useTranslation } from 'react-i18next';
import { Target } from '@phosphor-icons/react';

type Props = {
	selectedTeamId: string | undefined;
	onChange: (teamId: string | undefined) => void;
};

export const TeamSelect = ({ selectedTeamId, onChange }: Props) => {
	const { t } = useTranslation('dashboard');
	const org = useUserOrg();
	const { data: teams } = useGetOrgTeamsQuery({ orgId: org?.orgId ?? '' });
	if (!teams) {
		//TODO @Sherif: Should we return a skeleton here?
		return null;
	}

	return (
		<Select
			icon={<Target />}
			borderRadius={'10px'}
			borderColor={'black'}
			size={'sm'}
			maxW="fit-content"
			value={selectedTeamId}
			onChange={(e) => onChange(e.target.value)}
		>
			<option key="all" value="">
				{t('dashboard:all_teams')}
			</option>
			{teams.map((team) => (
				<option key={team.id} value={team.id}>
					{team.name}
				</option>
			))}
		</Select>
	);
};
