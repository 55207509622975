import { Box, Divider, Flex, Skeleton, Stack, Text } from '@chakra-ui/react';
import { Circle } from '@phosphor-icons/react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Chip } from '../../../../../common/components/Chip';
import { useGetFirstNotCompletedStory } from '../../../../../common/utils/getfirstNotCompletedStory';
import { useGetDetailedUserActivityOverviewQuery } from '../../../../../redux/api/dashboardApi';
import { StatusChipColorsMap } from '../../manager/user-activity/ActivityPerUserTable';

const LoadingState = () => {
	return <Skeleton borderRadius={'24px'} h="100px" />;
};
export const GeneralInfo = () => {
	const { t } = useTranslation('common');
	const navigate: NavigateFunction = useNavigate();
	const { userId } = useParams();
	const activeStory = useGetFirstNotCompletedStory();
	const { data, isLoading } = useGetDetailedUserActivityOverviewQuery(
		userId as string,
		{
			skip: !userId,
		}
	);

	if (isLoading) return <LoadingState />;
	if (!data) return null;

	return (
		<Box
			w="100%"
			p="6"
			border={'1px'}
			borderRadius={'24px'}
			borderColor={'#D9D9D9'}
		>
			<Stack
				alignItems={'center'}
				w={'100%'}
				divider={<Divider orientation="vertical" h="30px" />}
				direction={'row'}
				spacing="4"
			>
				<Flex w="100%" direction={'column'} gap="2">
					<Text fontSize={'12px'} color="#757575">
						{t('status')}
					</Text>
					<Chip
						gap={1}
						border={0}
						py="1"
						px="3"
						icon={<Circle size={10} weight="fill" />}
						bg={StatusChipColorsMap[data.status].bg}
						color={StatusChipColorsMap[data.status].text}
						fontWeight={'500'}
						text={t(`common:${data.status}`)}
					/>
				</Flex>
				{/*<Flex w="100%" direction={'column'} gap="2">*/}
				{/*	<Text fontSize={'12px'} color="#757575">*/}
				{/*		{t('team')}*/}
				{/*	</Text>*/}
				{/*	<Text fontWeight={'500'}>{data.team}</Text>*/}
				{/*</Flex>*/}
				<Flex w="100%" direction={'column'} gap="2">
					<Text fontSize={'12px'} color="#757575">
						{t('registered_since')}
					</Text>
					<Text fontWeight={'500'}>
						{format(data.registeredSince, 'MMM dd, yyyy')}
					</Text>
				</Flex>
				<Flex w="100%" direction={'column'} gap="2">
					<Text fontSize={'12px'} color="#757575">
						{t('last_session_on')}
					</Text>
					<Text fontWeight={'500'}>
						{data.lastSessionDate
							? format(data.lastSessionDate, 'MMM dd, yyyy')
							: '-'}
					</Text>
				</Flex>
				{activeStory && (
					<Flex w="100%" direction={'column'} gap="2">
						<Text fontSize={'12px'} color="#757575">
							{t('current_track')}
						</Text>
						<Text
							textDecoration={'underline'}
							cursor={'pointer'}
							onClick={() => navigate(`/gym`)}
						>
							{activeStory.journey.title}
						</Text>
					</Flex>
				)}
			</Stack>
		</Box>
	);
};
