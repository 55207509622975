import { Divider, Flex, Input } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppLayout } from '../../common/components/AppLayout';
import { Salutation } from '../../features/home/components/Salutation';
import { JourneyCard } from '../../features/manager-journeys/components/JourneyCard';
import { useGetJourneysDetailsQuery } from '../../redux/api/cmsApi';
import { useGetUserJourneyContentAllocationQuery } from '../../redux/api/learnerApi';

export const ManagerJourneysPage = () => {
	const { t } = useTranslation('common');
	const [search, setSearch] = useState('');

	const { data: userJourneys } = useGetUserJourneyContentAllocationQuery();
	const { data: journeys } = useGetJourneysDetailsQuery();

	if (!journeys || !userJourneys) {
		return null;
	}
	const filtered = journeys.filter((journey) => {
		const title = journey.title.toLowerCase();
		return title.includes(search.toLowerCase());
	});

	return (
		<AppLayout>
			<Salutation text={t('tracks') ?? ''} />
			<Divider mb={5} />
			<Input
				maxWidth={'400px'}
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				borderRadius={'8px'}
				placeholder={t('search_tracks') ?? ''}
			/>
			<Flex mt={6} direction={'column'} gap={4}>
				{filtered.map((journey) => {
					return <JourneyCard key={journey.id} journey={journey} />;
				})}
			</Flex>
		</AppLayout>
	);
};
