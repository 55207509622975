import { Box, Divider, SkeletonText, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useCalculateJourneyCompletion } from '../../../common/utils/calculateCompletion';
import { useGetFirstNotCompletedStory } from '../../../common/utils/getfirstNotCompletedStory';

const LoadingState = () => {
	return (
		<Box
			bg="#FCFCFC"
			border={'1px'}
			borderColor={'#D9D9D9'}
			borderRadius={'24px'}
			p="3"
			minW={'300px'}
			w="fit-content"
		>
			<SkeletonText noOfLines={5} spacing="5" />
		</Box>
	);
};

export const CurrentJourney = () => {
	const { t } = useTranslation('home');
	const activeStory = useGetFirstNotCompletedStory();
	const { completed, total } = useCalculateJourneyCompletion(
		activeStory?.journey
	);

	if (activeStory === null) {
		return null;
	}
	if (activeStory === undefined) {
		return <LoadingState />;
	}
	const { journey } = activeStory;
	return (
		<Box
			bg="#FCFCFC"
			border={'1px'}
			borderColor={'#D9D9D9'}
			borderRadius={'24px'}
			p="3"
			minW={'300px'}
			w="fit-content"
		>
			<Text fontSize={'12px'} color="#757575">
				{t('current_track')}
			</Text>
			<Text mt="2" fontSize={'16px'} fontWeight={'semibold'}>
				{journey.title}
			</Text>
			<Divider my="2" color={'#757575'} />
			<Text fontSize={'12px'} color="#757575">
				{completed}/{total}
			</Text>
		</Box>
	);
};
