import {
	Box,
	Flex,
	SkeletonText,
	Stack,
	Text,
	useToast,
} from '@chakra-ui/react';
import {
	SchemaSessionResponseDto,
	SessionResponseDTOAnalysisItemsSolved,
} from '@jam/api-sdk';
import { useAuthInfo } from '@propelauth/react';
import { skipToken } from '@reduxjs/toolkit/query';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../../common/components/custom-table/CustomTable';
import { useGetContentAllocated } from '../../../common/hooks/useGetContentAllocated';
import {
	useGetJourneysDetailsQuery,
	useGetUserStoriesQuery,
} from '../../../redux/api/cmsApi';
import { useGetAllSessionsByUserIdQuery } from '../../../redux/api/learnerApi';

type HistoryItem = {
	disableDetailView: boolean;
	story: string;
	storyId: string;
	sessionId: string;
	type: string;
	journey: string;
	date: Date;
	status: SchemaSessionResponseDto['analysis']['items'] | null;
};

export const SolvedStatusToColorMap: Record<
	SessionResponseDTOAnalysisItemsSolved,
	string
> = {
	[SessionResponseDTOAnalysisItemsSolved.solved]: '#73F5A2',
	[SessionResponseDTOAnalysisItemsSolved.partlySolved]: '#F8DE00',
	[SessionResponseDTOAnalysisItemsSolved.notSolved]: '#FF6767',
	[SessionResponseDTOAnalysisItemsSolved.undetected]: 'gray.500',
};

const LoadingState = () => {
	return (
		<Flex mt="5" direction={'column'} gap="6">
			<Box maxW="300px">
				<SkeletonText noOfLines={1} height={'30px'} />
			</Box>
			<SkeletonText w={'full'} noOfLines={5} spacing={'5'} />
		</Flex>
	);
};

export const HistoryTable = () => {
	const { t } = useTranslation('history');
	const { user } = useAuthInfo();
	// get all the sessions
	const { data, isLoading: sessionsLoading } = useGetAllSessionsByUserIdQuery(
		user?.userId ?? skipToken,
		{
			refetchOnMountOrArgChange: true,
		}
	);

	// get the user journey, need to get the journeyId
	const contentAllocated = useGetContentAllocated();
	// get the journey details to get the journeys
	const { data: journeysDetails } = useGetJourneysDetailsQuery();
	const navigate = useNavigate();
	// get all the stories to get the story details
	const { data: stories } = useGetUserStoriesQuery();
	const toast = useToast();

	if (sessionsLoading) return <LoadingState />;

	if (!data || !journeysDetails || !stories || data.length === 0) return null;

	const sessions: HistoryItem[] = data
		.map((session) => {
			// get the story details
			const story = stories?.find((s) => s.id === session.storyReferenceId);

			// get the first journey which has the story
			const journey = journeysDetails.find((tr) =>
				tr.stories.some((s) => s.id === session.storyReferenceId)
			);
			let type = t('from-gym');

			if (session.contentAllocationId) {
				const content = contentAllocated.find(
					(c) => c.id === session.contentAllocationId
				);
				type =
					content?.type === 'journey' ? t('from-journey') : t('from-refresher');
			}

			const conversationItemIds =
				story?.items_conversation_feedback.map((item) => item.id) ?? [];

			const sortedItems =
				session.analysis?.items.slice().sort((a, b) => {
					const aIndex = conversationItemIds.indexOf(
						a.conversationItemReferenceId ?? ''
					);
					const bIndex = conversationItemIds.indexOf(
						b.conversationItemReferenceId ?? ''
					);
					return aIndex - bIndex;
				}) ?? [];

			// return the history item object
			return {
				disableDetailView: !story,
				storyId: session.storyReferenceId,
				story: story?.title ?? t('story_deleted'),
				sessionId: session.id,
				type,
				journey: journey?.title || '',
				date: new Date(session.createdAt),
				status: sortedItems,
			};
		})
		.filter((item) => item !== null) as HistoryItem[];

	const columnHelper = createColumnHelper<HistoryItem>();
	const columns = [
		columnHelper.accessor('story', {
			header: t('mission').toString(),
			cell: ({ row }) => (
				<Text fontWeight={'medium'}>{row.original.story}</Text>
			),
		}),
		columnHelper.accessor('type', {
			header: t('type').toString(),
			cell: ({ row }) => <Text fontWeight={'medium'}>{row.original.type}</Text>,
		}),
		columnHelper.accessor('journey', {
			header: t('track').toString(),
			cell: ({ row }) => (
				<Text fontWeight={'medium'}>{row.original.journey}</Text>
			),
		}),
		columnHelper.accessor('date', {
			header: t('date').toString(),
			cell: ({ row }) => (
				<Text fontWeight={'medium'}>{format(row.original.date, 'MMM dd')}</Text>
			),
		}),
		columnHelper.accessor('status', {
			header: t('feedback').toString(),
			cell: ({ row }) => {
				const status = row.original.status;
				if (status === null) return null;

				return (
					<Stack direction={'row'} spacing={'4px'}>
						{status.map((item) => {
							return (
								<Box
									w="13px"
									h="13px"
									bg={
										item.solved
											? SolvedStatusToColorMap[item.solved]
											: SolvedStatusToColorMap[
													SessionResponseDTOAnalysisItemsSolved.undetected
												]
									}
									borderRadius={'full'}
									key={item.id}
								/>
							);
						})}
					</Stack>
				);
			},
		}),
	];

	return (
		<Box mt="5">
			<Text
				w="fit-content"
				fontSize="2xl"
				color={'#757575'}
				fontWeight="medium"
			>
				{t('learning_history')}
			</Text>
			<Box
				borderRadius={'24px'}
				mt="5"
				py="10"
				px="4"
				border={'1px'}
				borderColor={'#D9D9D9'}
			>
				<CustomTable<HistoryItem>
					data={sessions}
					columns={columns}
					onRowClick={(item) => {
						if (item.disableDetailView) {
							toast({
								title: t('story_deleted'),
								description: t('story_deleted_can_not_show_details'),
								status: 'info',
								duration: 9000,
								isClosable: true,
							});
						} else {
							navigate(
								`/call?storyId=${item.storyId}&sessionId=${item.sessionId}&ref=history&activeCallTabIndex=2`
							);
						}
					}}
				/>
			</Box>
		</Box>
	);
};
