import { AppLayout } from '../../common/components/AppLayout';
import {
	Flex,
	Stack,
	Tab,
	TabIndicator,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
} from '@chakra-ui/react';
import { UserActivity } from '../../features/dashboard/components/manager/user-activity/UserActivity';
import { useTranslation } from 'react-i18next';
import { TeamActivity } from '../../features/dashboard/components/manager/team-activity/TeamActivity';
import { UserWidgets } from '../../common/components/UserWidgets';
import { TeamSelect } from '../../features/dashboard/components/manager/team-activity/TeamSelect';
import { useState } from 'react';
import { useUserOrg } from '../../common/hooks/useUserOrg';
import { useGetOrgTeamsQuery } from '../../redux/api/dashboardApi';

export const ManagerDashboard = () => {
	const { t } = useTranslation('dashboard');
	const [selectedTeamId, setSelectedTeamId] = useState<string | undefined>(
		undefined
	);
	return (
		<AppLayout>
			<Flex mt={'-8px'} justifyContent={'space-between'}>
				<Text fontWeight={'medium'} fontSize="40px">
					{t('engagement_dashboard')}
				</Text>
				<UserWidgets />
			</Flex>
			<Text fontSize="16px" mt="5" color="#757575">
				{t('engagement_dashboard_description')}
			</Text>
			<Stack direction="column" spacing="5" mt={10}>
				<TeamSelect
					selectedTeamId={selectedTeamId}
					onChange={(teamId) => setSelectedTeamId(teamId)}
				/>
			</Stack>
			<Tabs mt="10" isLazy variant={'unstyled'}>
				<TabList borderBottom={0}>
					<Tab
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						{t('team_activity')}
					</Tab>
					<Tab
						fontWeight={'medium'}
						_selected={{ color: '#4241E4' }}
						fontSize={'16px'}
					>
						{t('user_activity')}
					</Tab>
				</TabList>
				<TabIndicator
					mt="-1.5px"
					height="2px"
					bg="#4241E4"
					borderRadius="1px"
				/>
				<TabPanels>
					<TabPanel px="0">
						<TeamActivity selectedTeamId={selectedTeamId} />
					</TabPanel>
					<TabPanel px="0">
						<UserActivity selectedTeamId={selectedTeamId} />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</AppLayout>
	);
};
