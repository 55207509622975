import { useParams } from 'react-router-dom';
import { AppLayout } from '../../common/components/AppLayout';
import { BreadCrumbs } from '../../features/manager-journeys/components/BreadCrumbs';
import { JourneyDetail } from '../../features/manager-journeys/components/JourneyDetail';
import { useGetJourney } from '../../features/manager-journeys/hooks/useGetCurrentJourney';

export const ManagerJourneyDetailsPage = () => {
	const { journeyId } = useParams();
	const journey = useGetJourney(journeyId);

	if (!journey) {
		return null;
	}
	return (
		<AppLayout>
			<BreadCrumbs
				backLink="/journeys"
				items={[
					{
						title: journey.title,
						link: `/journey/${journey.id}`,
					},
				]}
			/>
			<JourneyDetail />
		</AppLayout>
	);
};
