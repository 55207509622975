import { SchemaJourneyResponseDto, SchemaStoryResponseDto } from '@jam/api-sdk';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

const api = createApi({
	reducerPath: 'cms-api',
	baseQuery: fetchBaseQuery({
		baseUrl: `${
			process.env
				.REACT_APP_SALES_ENABLEMENT_PLATFORM_NEST_API_BASE_URL as string
		}/api/v1`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).applicationState.accessToken;

			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}

			return headers;
		},
	}),
	endpoints: (builder) => ({
		getStoryDetails: builder.query<SchemaStoryResponseDto, string>({
			query: (id: string) => `story/${id}`,
		}),
		getJourneysDetails: builder.query<SchemaJourneyResponseDto[], void>({
			query: () => `journey`,
		}),
		getJourneyDetails: builder.query<SchemaJourneyResponseDto, string>({
			query: (journeyId: string) => `journey/${journeyId}`,
		}),
		getUserStories: builder.query<SchemaStoryResponseDto[], void>({
			query: () => 'story',
		}),
		getUserGymStories: builder.query<SchemaStoryResponseDto[], void>({
			query: () => `gym`,
		}),
	}),
});

export const {
	useGetJourneysDetailsQuery,
	useGetJourneyDetailsQuery,
	useGetStoryDetailsQuery,
	useGetUserStoriesQuery,
	useGetUserGymStoriesQuery,
} = api;
export default api;
