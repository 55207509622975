import { Flex } from '@chakra-ui/react';
import { CompletionAcrossUsers } from './CompletionAcrossUsers';
import { WeeklyActivity } from './WeeklyActivity';
import { RoleplayAcrossUsersTable } from './RoleplayAcrossUsersTable';
import { UserOrgPendingInvites } from './UserOrgPendingInvites';

export const TeamActivity = ({
	selectedTeamId,
}: {
	selectedTeamId: string | undefined;
}) => {
	return (
		<Flex direction={'column'} gap={6} py="6">
			<CompletionAcrossUsers teamId={selectedTeamId} />
			<WeeklyActivity teamId={selectedTeamId} />
			<RoleplayAcrossUsersTable teamId={selectedTeamId} />
			<UserOrgPendingInvites />
		</Flex>
	);
};
