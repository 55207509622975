import { Box, Flex, SimpleGrid, Skeleton, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetDetailedUserCompletionsQuery } from '../../../../../redux/api/dashboardApi';
import { CompletionStat } from '../../manager/team-activity/CompletionStat';

const LoadingState = () => {
	return (
		<Flex direction={'column'} gap="4">
			<Box maxW="300px">
				<Skeleton borderRadius={'8px'} h="30px" flex={1} />
			</Box>
			<SimpleGrid mt="6" columns={2} gap={10} h={'100%'}>
				<Skeleton borderRadius={'8px'} h="150px" flex={1} />
				<Skeleton borderRadius={'8px'} h="150px" flex={1} />
				<Skeleton borderRadius={'8px'} h="150px" flex={1} />
				<Skeleton borderRadius={'8px'} h="150px" flex={1} />
			</SimpleGrid>
		</Flex>
	);
};

export const AllTimeCompletions = () => {
	const { t } = useTranslation('dashboard');
	const { userId } = useParams<{ userId: string }>();
	const { data, isLoading } = useGetDetailedUserCompletionsQuery(
		userId as string,
		{
			skip: !userId,
		}
	);

	if (isLoading) return <LoadingState />;
	if (!data) return null;

	return (
		<Flex direction={'column'} h="100%">
			<Text fontSize={'24px'} fontWeight={'500'} color={'#0B1215'}>
				{t('all_time_completions')}
			</Text>
			<SimpleGrid mt="6" columns={2} gap={10} h={'100%'}>
				<CompletionStat
					title={t('team_activity_section.tasks_completed')}
					hint={t('team_activity_section.tasks_completed_hint')}
					completions={data.overall.storyItemsCompleted}
					delta={data.trends.storyItemsCompleted}
				/>
				<CompletionStat
					title={t('team_activity_section.rounds_completed')}
					hint={t('team_activity_section.rounds_completed_hint')}
					completions={data.overall.sessionsCompleted}
					delta={data.trends.sessionsCompleted}
				/>
				<CompletionStat
					title={t('team_activity_section.missions_completed')}
					hint={t('team_activity_section.missions_completed_hint')}
					completions={data.overall.storiesCompleted}
					delta={data.trends.storiesCompleted}
				/>
				<CompletionStat
					title={t('team_activity_section.tracks_completed')}
					hint={t('team_activity_section.tracks_completed_hint')}
					completions={data.overall.journeysCompleted}
					delta={data.trends.journeysCompleted}
				/>
			</SimpleGrid>
		</Flex>
	);
};
